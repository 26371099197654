* {
  font-family: 'Abel', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-style: normal;
  margin: auto;
  padding: 10px;
  max-width: 1200px;
}

.MuiMasonry-root { margin: 0!important; }

@media (min-width: 1200px) {
  .AppBarTitle {
    font-size: 30px;
  }
  .AppBar {
    padding-bottom: 20px;
  }
}